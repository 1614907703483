import { httpCore } from './http.js'
import {store} from '../state/store'
export default {
    status(){
        return ['ACTIVE', 'FIRED', 'ABSENT', 'INACTIVE']
    },
    defaultNoPhoto(){
        return `/images/avatar/avatar-placeholder.jpg`;
    },
    photoUrl(photoId){
        if(this.id2 == null || this.id2 == '')
            return this.defaultNoPhoto();
        return `http://localhost:3000/file/download/${photoId}`
    },
    get(id) {
        return httpCore.get(`/category/${id}`);
    },
    delete(id) {
        return httpCore.delete(`/category/${id}`);
    },
    add(dto) {
        return httpCore.post(`/category/`, dto);
    },
    update(id, dto) {
        return httpCore.put(`/category/${id}`, dto);
    },


    getItemAysync(id) {
        var categories = store.state.config.categories;
        return categories.find(function (d) { return d._id == id });
    },

    async getList() {
        if (store.state.config.categories) { 
            return store.state.config.categories;
        } else {
            const { data } = await this.filter(1000000, 0, '', 'name', 'desc', {});
            store.commit('setCategories', data.data);
            return data;
        }
    },

    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/category-filter/",requestParams);
    },
}