/* eslint-disable */
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
const plList = () => import('@/pages-smartpl/pl/plList.vue');
const categoryList = () => import('@/pages-smartpl/category/categoryList.vue');
const plView = () => import('@/pages-smartpl/pl/plView.vue');
const clientList = () => import('@/pages-smartpl/client/clientList.vue');

const NotAllowed = () => import('@/pages-smartpl/notAllowed.vue');
const NotFound = () => import('@/pages/error/NotFound.vue');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const userList = () => import('@/pages-smartpl/user/userList.vue');
const dashboardView = () => import('@/pages-smartpl/dashboard/dashboardView.vue');
const configView = () => import("@/pages-smartpl/config/configView.vue");
const plEdit = () => import("@/pages-smartpl/pl/plEdit.vue");
const plCreate = () => import("@/pages-smartpl/pl/plCreate.vue");
const serviceList = () => import("@/pages-smartpl/service/serviceList.vue");

const agendaList = () => import("@/pages-smartpl/agenda/agendaList.vue");
const agendaBlockerList = () => import("@/pages-smartpl/agendaBlocker/agendaBlockerList.vue");
const agendaDateList = () => import("@/pages-smartpl/agendaDate/agendaDateList.vue");
const agendaView = () => import("@/pages-smartpl/agenda/agendaView.vue");
const routes = [
    {
      path: '/agenda',
      name: 'Agenda List',
      component: agendaList,
    },
    {
      path: '/agenda/:id',
      name: 'Agenda View',
      component: agendaView,
    },
    {
      path: '/categories',
      name: 'Category List',
      component: categoryList,
    },
    {
      path: '/agendaDate',
      name: 'Agenda Date List',
      component: agendaDateList,
    },
    {
      path: '/agendaBlockers',
      name: 'Agenda Blocker List',
      component: agendaBlockerList,
    },
    {
      path: '/services',
      name: 'Services List',
      component: serviceList,
    },
    {
      path: '/pl/:id',
      name: 'PL View',
      component: plView,
    }, 
    {
      path: '/pl/:id/edit',
      name: 'PL Edit',
      component: plEdit,
    }, 
    {
      path: '/pls',
      name: 'PL List',
      component: plList,
    },  
    {
      path: '/clients',
      name: 'Client List',
      component: clientList,
    },

    {
      path: '/pl/create',
      name: 'PL Create',
      component: plCreate,
    },

    {
      path: '/users',
      name: 'User List',
      component: userList,
    },
    {
      path: '/',
      name: 'Blank',
      component: dashboardView
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: dashboardView
    },
    {
    path: '/notAllowed',
    name: 'Not Allowed',
    component: NotAllowed
  },
  {
    path: '/config/',
    name: 'config View',
    component: configView
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },

  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
import {store, refreshSession} from '../state/store'
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`

  if (to.path.includes('changelog')) {
    window.location = 'https://fvtec.notion.site/Changelog-Smartbase-d03ec738278a46038ee1df20b3353ae4?pvs=4'
    return;
  }
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login','/auths/auth-reset'];
  if (publicRoutes.includes(to.path) || store.state.session.logged) {



    if (to.meta.permissionName) {
       
          var per = store.state.config.permissions;
          if (per && per.length >0) {
              if (!per.includes(to.meta.permissionName)) {
                next(`/notAllowed?permissionName=${to.meta.permissionName}`);
              } else {
                next();
            }
          } else {
             next();
          }

    } else {
      next();
    }

    

    
   
  
  }else{
    next('/auths/auth-login');
  }
})

export default router
